.sidebar {
  width: 20rem;
  height: calc(100vh - 4.5rem);
  padding: 1rem;
  background-color: lighten($gray-200, 10%);
  font-size: 0.95rem;
  display: none;
  margin-left: -50rem;
  transition: margin-left 1s;

  @media (max-width: 750px) {
    position: absolute;
  }

  h3 {
    position: relative;
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: 1px;
    margin-bottom: 1rem;

    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: lighten($black, 60%);
    }
  }

  > ul {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;

    color: lighten($black, 20%);

    > li {
      width: 100%;

      > ul {
        margin: 0.5rem 0;
        margin-left: 1.5rem;
        display: none;

        &.ul-active {
          display: block;
        }

        > li {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: -14px;
            height: 100%;
            background-color: lighten($black, 60%);
            width: 3px;
          }

          &:first-child {
            &::before {
              border-radius: 3px 3px 0 0;
            }
          }

          &:last-child {
            &::before {
              border-radius: 0 0 3px 3px;
            }
          }

          &:hover {
            color: $black;

            &:not(.active)::before {
              border-radius: 3px;
              background-color: lighten($black, 40%);
            }
          }
        }
      }

      > a {
        @include flex(row, center, space-between);

        width: 100%;
        text-transform: uppercase;
        font-weight: 600;

        &:hover {
          color: $black;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .active {
    > a {
      color: $black;
    }

    &::before {
      background-color: $red;
      border-radius: 3px;
    }
  }

  &.open {
    margin-left: 0;
    //position: absolute;
    display: block;
    width: calc(100vw - 1.5rem);
    max-width: 20rem;
    transition: margin-left 1s;
  }
}

.open-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: calc(100vh - 4.5rem);
  background: #fff6;

  @media (max-width: 750px) {
    position: absolute;

    &.open {
      left: 20rem;
    }
  }

  svg {
    font-size: 1.5rem;
    opacity: 0.5;
    transition: 0.5s;
  }

  &:hover {
    background: linear-gradient(to right, #0004, #0001);

    svg {
      opacity: 1;
    }
  }
}

.sidebar-container {
  display: flex;
}
