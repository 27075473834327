.footer {
  background: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footer-container,
  .copyright {
    width: 100%;
    max-width: 80vw;
  }

  .footer-container {
    display: flex;
    padding: 2rem;
    justify-content: space-around;

    @media (max-width: 900px) {
      justify-content: space-between;
    }

    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: center;
      align-items: start;

      section {
        h3 {
          margin-top: 1.5rem;
        }
      }
    }

    .logo {
      img {
        transform: scale(0.8);
      }
    }

    section {
      h3 {
        margin-bottom: 1rem;
        text-transform: uppercase;
      }

      ul {
        list-style: none;

        li {
          font-size: 0.9rem;
          margin: 0.25rem;
        }
      }

      .social-media {
        display: flex;

        svg {
          font-size: 1.75rem;
          margin: 0.25rem;

          transition: 0.2s;

          &:hover {
            fill: $red;
          }
        }
      }
    }
  }

  .copyright {
    padding: 1rem;
    font-size: 0.9rem;
    text-align: center;
    border-top: 1px solid white;
  }
}
