@keyframes float-right {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(5%);
  }

  50% {
    trasnform: translateX(0%);
  }

  75% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(0%);
  }
}
