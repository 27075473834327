.home {
  p {
    margin: 0.5rem 0;
    line-height: 1.5rem;
  }

  ol {
    margin: 0.5rem 1rem;
    list-style-type: upper-roman;
  }

  .flex-container {
    display: flex;
    text-align: center;

    padding: 0 5rem;

    @media (max-width: 900px) {
      padding: 0;
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }

    > section {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 700px) {
        width: 100%;
      }

      h2 {
        margin-top: 1rem;
      }

      a {
        text-decoration: none;
      }

      button {
        margin: 1rem;
        font-size: 1.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.75rem 1rem;
        border: none;
        background: $gray-200;
        border-radius: 6px;
        cursor: pointer;

        transition: 0.3s;

        svg {
          font-size: 1.4rem;
          margin-right: 0.75rem;
        }

        &:hover {
          background: $black;
          color: $white;
        }
      }
    }
  }
}
