.container {
  display: flex;

  main {
    width: 100%;
    height: calc(100vh - 4.5rem);
    padding: 2rem;
    overflow-x: hidden;

    header {
      h1 ~ * {
        margin-top: 0.5rem;
      }

      .subtopic {
        margin: 1rem 0;
      }
    }

    @media (max-width: 950px) {
      width: 100vw;
    }

    @media (max-width: 600px) {
      text-align: center;
      padding: 2rem 1rem;
    }
  }
}
